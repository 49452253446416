import React, { useEffect, useState } from 'react';
import { IS_DEVELOPMENT } from '@/utils/environment';

export interface DebugFontSizeProps {
  left?: number;
  bottom?: number;
}

const DebugFontSize = ({ left = 1, bottom = 2, ...props }: DebugFontSizeProps) => {
  const [fontSize, setFontSize] = useState('0');

  useEffect(() => {
    // mount
    let size = window.getComputedStyle(document.body).getPropertyValue('font-size');
    let outerWidth = window.outerWidth;
    if (fontSize === '0') {
      setFontSize(`${size} - ${outerWidth}`);
    }
    window.addEventListener('resize', () => {
      let size = window.getComputedStyle(document.body).getPropertyValue('font-size');
      setFontSize(`${size} - ${outerWidth}`);
    });
    // unmount
    return () => {};
  }, [fontSize]);

  return IS_DEVELOPMENT ? (
    <div style={{ position: 'fixed', left: `${left}rem`, bottom: `${bottom}rem`, fontWeight: 'bold', zIndex: 9000 }}>
      {fontSize}
    </div>
  ) : null;
};

export default DebugFontSize;
