import React, { ReactNode } from 'react';
import { Element } from './SectionFeaturedContent.styles';

export interface SectionFeaturedContentProps {
  className?: string;
  children?: ReactNode;
}

const SectionFeaturedContent = ({ children, ...props }: SectionFeaturedContentProps) => {
  return <Element>{children}</Element>;
};

export default SectionFeaturedContent;
