import React, { ReactNode } from 'react';
import { SectionElement } from '@/components/section/SectionFeatured/SectionFeatured.styles';
import { Container } from '@/components/container';
import TitleFeatured from '@/components/typography/TitleFeatured/TitleFeatured';
import { DefaultTheme } from 'styled-components';

export interface SectionFeaturedProps {
  title: string;
  description?: string;
  bgColor?: keyof DefaultTheme['color'];
  className?: string;
  children?: ReactNode;
}

const SectionFeatured = ({ title, description, children, ...props }: SectionFeaturedProps) => {
  return (
    <SectionElement {...props}>
      <Container flex flexDirection="column">
        <TitleFeatured as="h2" title={title} description={description} />
        {children}
      </Container>
    </SectionElement>
  );
};

export default SectionFeatured;
