import styled, { css } from 'styled-components';
import { ContainerProps } from '@/components/container/Container';

export const Element = styled.div<ContainerProps>`
  ${({ theme, size, flex, justify, align, flexDirection, position }) => {
    return css`
      width: 100%;
      margin: 0 auto;

      // Flex
      ${flex &&
      css`
        display: flex;
      `};

      // Flex content
      ${justify &&
      css`
        justify-content: ${justify};
      `};

      // flex direction
      ${flexDirection &&
      css`
        flex-direction: ${flexDirection};
      `};

      // Align Items
      ${align &&
      css`
        align-items: ${align};
      `};

      // Position
      ${position &&
      css`
        position: ${position};
      `};

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        max-width: ${size ? theme.container[size] : theme.container.xl};
      }

      // Adds small spacing in certain breakpoint lower then 1440
      @media screen and (min-width: ${theme.breakpoint.md}) and (max-width: ${theme.breakpoint.xl}) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    `;
  }};
`;
