import styled, { css } from 'styled-components';
import { SectionFeaturedContentProps } from './SectionFeaturedContent';

export const Element = styled.div<SectionFeaturedContentProps>`
  ${({ theme, ...props }) => {
    return css`
      position: relative;
    `;
  }};
`;
