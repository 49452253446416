import React, { ReactNode } from 'react';
import { Element } from '@/components/section/SectionFeaturedBottom/SectionFeaturedBottom.styles';

export interface SectionFeaturedBottomProps {
  children?: ReactNode;
}

const SectionFeaturedBottom = ({ children, ...props }: SectionFeaturedBottomProps) => {
  return <Element>{children}</Element>;
};

export default SectionFeaturedBottom;
