import React, { HTMLAttributes, ReactNode } from 'react';
import { Element } from './Container.styles';

export interface ContainerProps {
  /**
   * Responsive sizes to expand the container content.
   * */
  size?: 'sm' | 'md' | 'lg' | 'xl';
  flex?: boolean;
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse' | 'initial';
  justify?: 'space-between';
  position?: 'relative';
  align?: 'center';
  className?: string;
  children?: ReactNode;
}

/**
 * Responsive container for laying out content.
 * */
const Container = ({ size, children, ...props }: ContainerProps & HTMLAttributes<any>) => {
  return (
    <Element size={size} {...props}>
      {children}
    </Element>
  );
};

export default Container;
