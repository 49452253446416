import styled, { css } from 'styled-components';
import { SectionFeaturedProps } from './SectionFeatured';
import { Element as ContainerElement } from '@/components/container/Container.styles';

export const SectionElement = styled.section<Omit<SectionFeaturedProps, 'title' | 'description'>>`
  ${({ theme, bgColor, ...props }) => {
    return css`
      display: flex;
      flex-direction: row;
      gap: ${theme.spacing.base};
      padding: ${theme.spacing.md} ${theme.spacing.base};

      ${bgColor &&
      css`
        background: ${theme.color[bgColor]};
      `};

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        padding: ${theme.spacing.xxl} 0;
      }

      > ${ContainerElement} {
        gap: ${theme.spacing.md};
      }
    `;
  }};
`;
