import React from 'react';
import dynamic from 'next/dynamic';

const SectionCertification = dynamic(() => import('./SectionCertification'));

const SectionCertificationLazy = () => {
  return <SectionCertification />;
};

export default SectionCertificationLazy;
