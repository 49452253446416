import React from 'react';
import { Main } from './Home.styles';
import { BannerHero } from '@/components/banner/BannerHero';
import { SectionFeatured, SectionFeaturedBottom, SectionFeaturedContent } from '@/components/section';
import Button from '../../components/button/Button/Button';
import DebugFontSize from '@/components/debugger/DebugFontSize';
import SectionCertificationLazy from '@/components/section/SectionCertification';
import FeaturedFlightLazy from '@/features/home/components/FeaturedFlight';
import FeaturedHoneymoon from '@/features/home/components/FeaturedHoneymoon';
import CarrouselFeaturedPackage from '@/features/home/components/CarrouselFeaturedPackage';
import CarrouselFeatured from '@/features/home/components/CarrouselFeatured';
import FeatureDestination from '@/features/home/components/FeatureDestination';
import Footer from '@/components/footer/Footer';
import WhatsAppFAB from '@/components/button/WhatsAppFAB';
import HtmlFormatter from '@/components/content/HtmlFormatter/HtmlFormatter';
import CookieConsent from '@/components/popup/CookieConsentLazy';
import TopScroll from 'src/components/top-scroll';

export interface HomeProps {
  data: HomePageData;
}

const Home = ({ data, ...props }: HomeProps) => {
  const {
    field,
    description,
    banners,
    featuredReveillon,
    featuredPacotes,
    featuredFlights,
    featuredHoneyMoon,
    featuredContinentMaps,
  } = data;

  return (
    <>
      <Main>
        {(banners.length && <BannerHero banners={banners as [BannerType]} />) || null}
        {description.length > 5 && (
          <SectionFeatured title="Sobre a Kangaroo">
            <SectionFeaturedContent>
              <HtmlFormatter
                align="center"
                dangerouslySetInnerHTML={{
                  __html: description
                    .replace(/(<div>)|(<\/div>)/g, '<p>')
                    .replace(/(<\/div>)/g, '</p>')
                    .replace(/<p><\/p>/g, ''),
                }}
              />
            </SectionFeaturedContent>
          </SectionFeatured>
        )}
        {(featuredReveillon.length && (
          <SectionFeatured
            title={field['featured-reveillon-heading']?.value || ''}
            description={field['featured-reveillon-description']?.value || ''}
          >
            <SectionFeaturedContent>
              <CarrouselFeatured data={featuredReveillon as unknown as [FeaturedReveillonType]} />
            </SectionFeaturedContent>
            <SectionFeaturedBottom>
              <Button href="/viagens/reveillon">Veja todas as opções de réveillon</Button>
            </SectionFeaturedBottom>
          </SectionFeatured>
        )) ||
          null}

        {(featuredPacotes.length && (
          <SectionFeatured
            title={field['featured-offers-heading']?.value || ''}
            description={field['featured-offers-description']?.value || ''}
          >
            <SectionFeaturedContent>
              <CarrouselFeaturedPackage data={featuredPacotes as [FeaturedOfferType]} />
            </SectionFeaturedContent>
          </SectionFeatured>
        )) ||
          null}

        {featuredFlights.length && (
          <FeaturedFlightLazy
            title={field['featured-flights-heading']?.value}
            description={field['featured-flights-description']?.value}
            offers={featuredFlights}
          />
        )}

        {featuredHoneyMoon.length && (
          <SectionFeatured
            title={field['featured-honeymoon-heading']?.value || ''}
            description={field['featured-honeymoon-description'].value}
          >
            <SectionFeaturedContent>
              <FeaturedHoneymoon data={featuredHoneyMoon as [FeaturedHoneyMoonType]} />
            </SectionFeaturedContent>
          </SectionFeatured>
        )}

        {featuredContinentMaps.length && (
          <SectionFeatured title="Conheça todos os destinos" bgColor="neutral_100">
            <SectionFeaturedContent>
              <FeatureDestination data={featuredContinentMaps} />
            </SectionFeaturedContent>
            <SectionFeaturedBottom>
              <Button href="/destinos">Veja todos os destinos</Button>
            </SectionFeaturedBottom>
          </SectionFeatured>
        )}

        <SectionCertificationLazy />
      </Main>
      <WhatsAppFAB />
      <Footer />
      <DebugFontSize />
      <CookieConsent />
      <TopScroll />
    </>
  );
};

export default Home;
