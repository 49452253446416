import styled, { css } from 'styled-components';

export const Main = styled.main`
  ${({ theme, ...props }) => {
    return css`
      position: relative;
      margin-top: -6rem; // height of navbar
      z-index: 1;
    `;
  }};
`;
