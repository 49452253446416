import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme, ...props }) => {
    return css`
      text-align: center;
      a,
      button {
        width: 100%;
      }

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        a,
        button {
          width: auto;
        }
      }
    `;
  }};
`;
