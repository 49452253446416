import { createContext, useContext } from 'react';

export type AppContextType = {
  data?: {
    meta: Metadata;
  };
  navigation: NavigationType;
};

const AppContext = createContext<AppContextType>({} as AppContextType);

export function useAppContext() {
  return useContext(AppContext);
}

export default AppContext;
