import Header from '@/components/header';
import { GetStaticProps, GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import HeadMetaTag from '@/components/metatags/HeadMetaTag';
import { fetchDataHandler, fetchNavigation } from '@/lib/fetcher';
import { Home } from 'src/features/home';
// Import Swiper styles
import 'swiper/css';
import CustomError from '@/features/errors';
import Popup from '@/components/popup';
import React from 'react';
import AppProvider from '@/context/AppProvider';

export const getStaticProps: GetStaticProps = async (req: GetStaticPropsContext) => {
  const path = `/`;
  const [navigation, pageProps] = await Promise.all([
    fetchNavigation(),
    fetchDataHandler(path, `[HOMEPAGE] PAGE: ${path}`),
  ]);

  const { props, notFound, redirect } = pageProps;

  if (notFound !== undefined) return { notFound: true };
  if (redirect !== undefined) return { redirect };
  if (props !== undefined) return { props: { ...props, navigation }, revalidate: 30 };

  return {
    props: {
      data: null,
    },
  };
};

export default function Index({ data, error, ...props }: InferGetStaticPropsType<typeof getStaticProps>) {
  if (error) {
    return <CustomError error={error} />;
  }

  // return <pre>{JSON.stringify(props.navigation.user, null, 2)}</pre>;

  return (
    <AppProvider navigation={...props.navigation}>
      <HeadMetaTag meta={data.meta} />
      <Header />
      <Home data={data} />
      <Popup />
    </AppProvider>
  );
}
